/*custom fonts*/
@font-face {
  font-family: "SweetDreamers";
  src: url("//cdn.shopify.com/s/files/1/0282/7996/3745/files/AntagometricaBT-Bold.woff") format("woff");
  font-weight: bold;
} 

@font-face {
  font-family: "SweetDreamers";
  src: url("//cdn.shopify.com/s/files/1/0282/7996/3745/files/AntagometricaBT-Regular.woff") format("woff");
  font-weight: normal;
} 
@font-face {
  font-family: "SweetDreamersCursive";
  src: url("//cdn.shopify.com/s/files/1/0282/7996/3745/files/duffyscript_bold-webfont.woff") format("woff");
  font-weight: bold;
} 
@font-face {
  font-family: "SweetDreamersCursive";
  src: url("//cdn.shopify.com/s/files/1/0282/7996/3745/files/duffyscript_demibold-webfont.woff") format("woff");
  font-weight: normal;
} 
.btn, .rte .btn, .shopify-payment-button .shopify-payment-button__button--unbranded {
	letter-spacing: 0em;
}

.faux-select,body,button,input,select,textarea{
	 font-family: SweetDreamers;
}

.multibuy-price-block {
    margin-top: 10px;
    /*background: #f5f5f5;*/
    color:#777;
    padding: 15px 11px;
    font-size: 12px;
    border-radius: 5px;
    border: solid 1px #e8e8e1;
}

.label,label:not(.variant__button-label):not(.text-label){
	letter-spacing: 0em;
    font-weight: 700;
    font-size: var(--typeBaseSize);
    text-transform: lowercase;
}

.h1,.h2,.h3,h1,h2,h3{
	font-family: SweetDreamers;
	text-transform: lowercase;
}

.btn,.rte .btn,.shopify-payment-button .shopify-payment-button__button--unbranded,.spr-container .spr-button,.spr-container .spr-summary-actions a{
	letter-spacing:0em;
}


.collapsible-trigger-btn{
	letter-spacing:0em;
	font-weight: bold;
	font-size: calc(var(--typeBaseSize)*.92);
	text-transform:lowercase;
}

.grid__image-ratio--portrait:before{
	padding-bottom:125.5%;
}

.drawer{
	z-index:90001;
}

.animation-cropper{
	overflow:visible;
}

.spr-header-title{
	font-family: SweetDreamers;
}

.spr-header-title{
	font-family: SweetDreamers;
}

.site-header--heading-style .site-nav__link{
	font-family: SweetDreamers;
}
.site-nav__link {
  color:#fff;
  font-size: 14px;
  
}

.site-nav__link:focus {
  color:#fff;
}
.site-nav__link:hover {
  color:#fff;
}


#shopify-section-header .site-nav__link, #shopify-section-header .site-nav__dropdown-link:not(.site-nav__dropdown-link--top-level) {
 color:#fff;
  font-size: 14px;
}
.site-nav__link, .site-nav__dropdown-link:not(.site-nav__dropdown-link--top-level) {
   color:#fff;
  font-size: 14px !important;
}
.site-nav--has-dropdown .site-nav__link--underline:after {
    border-bottom: none;
}
.site-nav .site-nav__dropdown {
    background: #161e3d !important;
}
.site-nav__dropdown a {
  background: #161e3d;
}
.site-nav__item .icon-chevron-down {
  display:none;
}
.site-nav__item:focus{
  color:#ce9b50 !important;
   background: #161e3d;
}
.site-nav__item:hover{
   color:#ce9b50 !important;
   background: #161e3d;
}

.mobile-nav--heading-style .mobile-nav__link--top-level{
	font-family: SweetDreamers;
}

.hero__top-subtitle{
	text-transform:lowercase;
	letter-spacing:0em;
	font-size:1.1em;
}

.hero__subtitle{
	font-size:2em;
	margin-top: -20px;
}

.grid-product__title--heading{
	 font-family: SweetDreamers;
}

.announcement-text{
	 font-size:0.9em;
     text-transform:none;
     letter-spacing: 0px;
   font-family: SweetDreamers;
}

.type-advanced .rte--strong,.type-product .rte--strong,.type-sale_collection .rte--strong{
	 font-family: SweetDreamers;
}

.collapsible-trigger-btn--borders{
	border-left: 0;
	border-right: 0;
}

.collapsible-trigger-btn--borders+.collapsible-content .collapsible-content__inner{
	border-left:0;
	border-right: 0;
}
.footer__section{
	margin-bottom:0px
}

.payment-icons{
	margin: 40px auto 20px auto;
}

.toolbar{
	z-index: 30;
    position: relative;
    box-shadow: 0 0 1px rgba(0,0,0,0.2);
}

.mobile-nav__social a{
	padding:0px 0px;
}

.mobile-nav__social-item{
	flex:0 1 48%;
}
.blogBody img {
 display:inline-block; 
}

.mobile-nav__social-item:nth-child(1){
	 margin-right: 4%;
}

.slideshow__slide .hero__image-wrapper,[data-aos=hero__animation] .hero__image-wrapper{
	transform:translateX(0px);
}

.slideshow__slide.animate-out .hero__image-wrapper{
	transform:translateX(0px);
}

.collection-filter__item--count{
	font-size: 12px;
}


.variant-wrapper+.variant-wrapper{
	 margin-top:0px;
}

.grid-product__price{
	font-weight: bold;
	font-size: 16px;
    letter-spacing: 0;
}

.article__grid-meta{
	 padding: 0 10px;
}

.page-content--product .product__price {
    font-size:14px;
  font-weight:700;
   color:#000000;
}
.page-content--product .product__price--compare{
color:#bf2e35;
}
.grid-product__price {
  font-size:14px;
  font-weight:700;
  color:#000000;
}
.grid-product__price--original {
  color:#bf2e35;
}

.grid-product__title {
  color:#000000;
}




@media only screen and (max-width:768px){
	  .sales-point .icon-and-text{
		   justify-content:left;
	}
	.product-single__meta{
		text-align:left;
	}
	.site-footer{
		padding-bottom:20px;
	}
}

@media only screen and (min-width:769px){
	.collection-item__title--heading{
		 font-family: SweetDreamers;
	}
	
}


  .page-content--bottom .btn {
    margin:0 15px 20px;
    max-width: calc(100% - 30px);
  }
  
.shopify-cleanslate div[role="button"] {
  min-height: 50px !important;
  border-radius: var(--buttonRadius);
}
.sales-points {
    margin: 0px 0;
    background: #f5f5f5;
    padding: 15px 11px;
  font-size: 14px;
  border-radius:5px;
}
.sales-point {
  margin-bottom:0px;
}

.product-single__meta .product__policies {
  display:none;
}

.shopify-app-block *::part(osm-container) {
  background:#ffffff;
  font-family: SweetDreamers;
  border:none;
}
.shopify-app-block *::part(osm-legal) {
  display:none;
}

  
.slideshow__slide .hero__title {
	font-family: SweetDreamersCursive;
	font-size: 150px !important;
    margin-top: -50px;
	letter-spacing: 0;
}
.promo-grid__text .rte--em {
	    font-size: 12px;
}

.hero {
	margin-bottom: 20px;
}

.feature-row__item--overlap-images img {
	border-radius: 50%;
	
}
.feature-row__item--overlap-images .image-wrap {
    background: transparent;
}

.footer__title {
	text-transform: lowercase;
	letter-spacing: 0;
	font-weight: bold;
	    font-size: 16px;
	color: #333232;
}

	.subFooterMenu {
		width: 100%;
		float: left;
		background: #ddd;
		font-size: 12px;
		
	}
	.subFooterMenu ul li {
		display: inline;
		margin: 20px 20px 20px 0;
		
	}
	.subFooterMenu ul {
		float: right;
		margin: 20px;
	}
	
	.subFooterMenu .site-footer__copyright-content {
	line-height: 60px;
    margin-left: 20px;
	}
.payment-icons li:nth-child(1) {
	margin-left: 0;
}
.productHelpHolder a:not(.btn):not(.spr-summary-actions-newreview):after {
	border-bottom: none!important;
}



/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: #fff;
  border: none;
  border-bottom: #e9e9e9 1px solid ;
  color: #3a3a3a;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  font-weight: 700;
  text-align: left;
  outline: none;
 
}

.accordion:nth-of-type(1) {
	border-top: #e9e9e9 1px solid ;
}
.active {
	border-bottom: none;
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active:after {
  transform: rotate(45deg);
  font-weight: 300;
}
button.accordion:after {
	transition: all 0.4s;
    content: '\002B';
    color: #3a3a3a;
    font-weight: bold;
    float: right;
    margin-left: 5px;
}

.panel {
  padding: 0 20px 20px;
  background-color: white;
  display: none;
  overflow: hidden;
  color: #777;
  border-bottom: 1px solid #e9e9e9;
}

.panel p {
  color: #777;
}
/* Style the accordion panel. Note: hidden by default */

.productFaqs {
	width: 100%;
	float: left;
	margin-top: 60px;
	margin-bottom: 60px;
}

.productFaqs h3 {
	font-size:calc(var(--typeHeaderSize)*0.7);
	font-weight: 700;
	padding-left: 18px;
	margin-bottom: 30px;
}

.panel table svg {
	width: 15px;
	
}
.panel table {
    margin-top: 20px;
}

.panel table th {
	font-weight: 700;
	   font-family: "SweetDreamers";
}

.panel table th:nth-child(n+2) {
	text-align: center;
	
}

.panel table td:nth-child(n+2) {
	text-align: center;
}
.panel table td {
    height: 100px;
	}

.panel table .no {
	background: #f9f9f9;
}

.panel table .no svg {
	width: 11px;
}

 .lullaby-trust-badge {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 70px !important;
            height: 70px !important;
        }

#shopify-product-reviews .spr-container.spr-container {
	text-align: left;
	padding: 20px;
}

#shopify-product-reviews .spr-container .spr-summary.spr-summary {
	text-align: left;
}
#shopify-product-reviews .spr-header-title {
    text-align: left;
}
#shopify-product-reviews .spr-header {
	border-bottom: #e9e9e9 1px solid;
    padding-bottom: 30px;
}

#shopify-product-reviews .spr-content .spr-review-header-title {
	font-family: 'SweetDreamers';
}
.featuresIcons {
   
    float: left;
    width: 100%;
    margin-top: 5px;
    padding: 0px;
}
.quickLook {
	list-style-type: none !important;
	margin: 0 !important;
}

.quickLook li {
	display: inline-block;
	width: 16.6%;
	padding: 2.5px;
	text-align: center;
	float: left;
	font-size: 15px;
    font-weight: 400;
	color: #3A3A3A;
}

.classic li {
	width: 25%;
}

.quickLook li div {
	width: calc(50% - 5px);
	border-radius: 50%;
	height: auto;
    padding-top: calc(50% - 7px);
	margin: 0 auto 5px;
	position: relative;
}
.quickLook li div svg {
    position: absolute;
    top: 50%;
    left: 50%;
	max-width: 100px;
    -webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
    width: 100%;
}

.colourWays {
	width: 100%;
  margin-top: 20px;
	
}
.colourWays h4 {
letter-spacing: 0em;
    font-weight: 700;
    font-size: var(--typeBaseSize);
    text-transform: lowercase;
}

.colourWays div {
	width: 30px;
	height: 30px;
	display: inline-block;
    margin: 0 10px 0 0;
	border-radius: 50%;
	border: solid 2px #eee;
}
.colourWays div div {
margin: 3px 3px;
    border: none;
    width: 20px;
    height: 20px;
    background: #fff;
    display: block;
}

.colourWays .purple div {
	background-color: #69578f;
}
.colourWays .grey div {
	background-color: #a2a29d;
}
.colourWays .beige div {
	background-color: #e6d9c7;
}

 .colourWays .pink div {
    background-color: #fca6cb;
  }

 .colourWays .white div {
    background-color: #ffffff;
  }

 .colourWays .blue div {
    background-color: #96cce1;
  }

.drawer__title img {
	height: 50px;
}
.productDescriptor {
	font-size: calc(var(--typeHeaderSize)*.5);
	color: #777;
	width: 100%;
	display: inline-block;
}

.grid-product__title .productDescriptor {
    font-size: 14px;
	}
.panel .table-wrapper {
	display: inline-block;
}
  .slideshow__slide--1644414617e61a87e8-0 .hero__title {
    font-family: SweetDreamers;
        font-size: 80px !important;
    margin-top: -10px;
  }
  
  .slideshow__slide--1644414617e61a87e8-0  .hero__subtitle {
     margin-top: 0px;
    font-size: 25px;
  }


  #localization_formfooter {
    margin:20px auto;
  }
  
 /*custom homepage hero image banner*/
	
	.customHeroImage {
	height: 650px;
    width: 100%;
	position: relative;
	overflow: hidden;
	}
	.customHeroImage img {
	width: 100%;
	position:absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	-moz-transform: translate(-50%,-50%);
	-o-transform: translate(-50%,-50%);
	-ms-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	}

	.customHeroImage .heroText {
	position: absolute;
    z-index: 1;
    width: 100%;
    max-width: 1500px;
    height: 100%;
	left: 50%;
	color: #fff;
	-webkit-transform: translateX(-50%);
	-moz-transform: translateX(-50%);
	-o-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	padding: 0 40px;
	}
	.customHeroImage .heroText .topSubtitle {
	text-transform: lowercase;
    letter-spacing: 0em;
    font-size: 1.1em;
	position: absolute;
	bottom: 245px;
    
	}
	.customHeroImage .heroText .btn {
		background: #fff !important;
		color: #000;
	
	}


	
	.customHeroImage .heroText .heroTitle {
	font-family: SweetDreamersCursive;
    font-size: 150px !important;
    letter-spacing: 0;
	position: absolute;
	bottom: 120px;
	}
	.customHeroImage .heroText.alt .heroTitle {
	font-family: SweetDreamers;
    font-size: 80px !important;
    bottom: 130px;
	}
	.customHeroImage .heroText .heroSubtitle {
	font-size: 2em;
    position: absolute;
	bottom: 110px;
	}
	.customHeroImage .alt .heroSubtitle {
	font-size: 25px;
	}
.customHeroImage .heroText.alt .topSubtitle {
			bottom: 230px;
		}
	.links {
	position: absolute;
	bottom: 50px;
    width:100%;
	}
	.custom__item .heroMobile {
	display: none;
	background-size: cover;
	background-position: top;
	}
	.custom__item .heroDesktop {
	display: block;
	background-size: cover;
	background-position: center;
	}
	img.heroMobile {
		opacity: 0;
	}
	img.heroDesktop {
		opacity: 0;
	}
	.bannerImageHolder {
		height: 100%;
	}
	.imageHolder {
		height: 100%;
	}
	.heroDesktop {
          box-shadow: inset 0px -270px 40px rgba(1, 1, 1, 0.3);
      }
	@media only screen and (max-width: 1580px) {
	.customHeroImage .heroText {
		width: calc(100% - 80px);
		padding: 0;
	}
	}
	@media only screen and (max-width: 750px) {
		.customHeroImage .heroText.alt .heroTitle {
    font-size: 50px !important;
			bottom: 135px;
	}
      .customHeroImage .heroText.alt.twoBtn .heroTitle  {
    font-size: 50px !important;
			bottom: 195px;
	}
      .customHeroImage .heroText .heroTitle {
        bottom: 100px;
      }
		
		.customHeroImage .alt .heroSubtitle {
	font-size: 20px;
      
		}

.customHeroImage .heroText.twoBtn .heroSubtitle {
	 bottom:150px;
      
		}
      .customHeroImage .heroText.twoBtn .heroTitle  {
	 bottom:155px;
      
		}
       .customHeroImage .heroText.twoBtn .topSubtitle  {
	 bottom:280px;
      
		}
      
      .customHeroImage .heroText .heroSubtitle {
        bottom:90px;
         
      }
      .customHeroImage .heroText.alt .topSubtitle {
            bottom: 210px;
      }
      .customHeroImage .heroText.alt.twoBtn .topSubtitle {
            bottom: 270px;
      }
       .customHeroImage .heroText .topSubtitle {
            bottom: 240px;
      }
	.custom__item .heroMobile {
	display: block;
	}
	.custom__item .heroDesktop {
	display: none;
	}
	.customHeroImage {
	height: 730px;
	}
      .heroMobile {
          box-shadow: inset 0px -320px 40px rgba(1, 1, 1, 0.3);
      }

      .links {
        bottom:20px;
      }
      .customHeroImage .links a {
        width:100%;
        margin:10px 0;
      }
      
	.customHeroImage .heroText {
		width: calc(100% - 40px);
		
	}
	}
/*custom homepage hero image banner end*/
	
  .multibuyBadge {    
    font-size: calc(var(--typeBaseSize)*.65); 
    padding: 5px 7px;
    background-color: var(--colorSaleTag);
    color:#ffffff;
    display: inline-block;
    border-radius:5px;
  }

  .desktop {display:block;}
  .mobile {display:none;}

  .quote {
    padding: 60px 40px;
    width: calc(100% - 40px);
    font-style: italic;
    font-size: 18px;
    font-weight: 700;
  }

   .quote::before {
  content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQuMDE3IDIxdi03LjM5MWMwLTUuNzA0IDMuNzMxLTkuNTcgOC45ODMtMTAuNjA5bC45OTUgMi4xNTFjLTIuNDMyLjkxNy0zLjk5NSAzLjYzOC0zLjk5NSA1Ljg0OWg0djEwaC05Ljk4M3ptLTE0LjAxNyAwdi03LjM5MWMwLTUuNzA0IDMuNzQ4LTkuNTcgOS0xMC42MDlsLjk5NiAyLjE1MWMtMi40MzMuOTE3LTMuOTk2IDMuNjM4LTMuOTk2IDUuODQ5aDMuOTgzdjEwaC05Ljk4M3oiLz48L3N2Zz4=");
     position: relative;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
}

.loox-rating-label {
  font-weight: 700;
  margin-left: -3px;
  margin-top: 1px;
}

.loox-rating {
  margin-bottom: 10px;
}

.varientWorkAround {
    position: relative;
    display: inline-block;
    font-weight: 400;
    font-size: var(--typeBaseSize);
    font-family: var(--typeBasePrimary),var(--typeBaseFallback);
    cursor:pointer;
    padding: 7px 15px;
    margin: 0 8px 12px 0;
    background-color: #fff;
    background-color: var(--colorBody);
    box-shadow: 0 0 0 1px #e8e8e1;
    box-shadow: 0 0 0 1px var(--colorBorder);
    overflow: hidden;
    }
  .varientWorkAround:hover {
    box-shadow: 0 0 0 2px #000;
  }
  .varientWorkAround.disabled {
    color: var(--colorBorder);
}
  .varientWorkAround.disabled:after {
    border-left: 1px solid;
    border-color: var(--colorBorder);
    bottom: 0;
    content: "";
    left: 50%;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
}
.varientWorkAround div {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 0 0px 0 0;
    border-radius: 50%;
   /* border: solid 2px #eee;*/
    position: absolute;
    top: 5px;
    left: 5px;
}
.colourVarient span {
    width: 30px;
    height: 30px;
    display: inline-block;
    margin: 0 0px 0 0;
    border-radius: 50%;
   /* border: solid 2px #eee;*/
    position: absolute;
    top: 5px;
    left: 5px;
}
.colourVarient {
padding: 7px 15px 7px 36px !important;
}
.varientWorkAround div div {
    margin: 0px;
    position: absolute;
   /*  top:3px;
    left:3px;*/
    top:5px;
    left:5px;
    border: none;
    width: 20px;
    height: 20px;
    background: #fff;
    display: block;
}
.colourVarient span span {
    margin: 0px;
    position: absolute;
   /*  top:3px;
    left:3px;*/
    top:5px;
    left:5px;
    border: none;
    width: 20px;
    height: 20px;
    background: #fff;
    display: block;
}
.varientWorkAround .grey div {
    background-color: #a2a29d;
} 
.disabled .grey div {
    opacity:0.5;
} 
.colourVarient .grey span {
    background-color: #a2a29d;
} 
.disabled .grey span {
    opacity:0.5;
} 
.varientWorkAround .pink div {
    background-color: #fca6cb;
} 
.disabled .pink div {
    opacity:0.5;
} 
.colourVarient .pink span {
    background-color: #fca6cb;
} 
.disabled .pink span {
    opacity:0.5;
} 
.varientWorkAround .greyStripe div {
    background: linear-gradient(45deg, #e2e1de 25%, #a2a29d
             25%, #a2a29d 50%, #e2e1de 
             50%, #e2e1de 75%, #a2a29d 
             75%);
  background-size: 10px 10px;
} 
.disabled .greyStripe div {
    opacity:0.5;
} 
.disabled .greyStripe span {
    opacity:0.5;
} 
.colourVarient .greyStripe span {
    background: linear-gradient(45deg, #e2e1de 25%, #a2a29d
             25%, #a2a29d 50%, #e2e1de 
             50%, #e2e1de 75%, #a2a29d 
             75%);
  background-size: 10px 10px;
} 
.varientWorkAround .blueMoon div {
    background-color: #192f5e;
} 
.disabled .blueMoon div {
    opacity:0.5;
} 
.disabled .blueMoon span {
    opacity:0.5;
} 
.colourVarient .blueMoon span {
    background-color: #192f5e;
} 
  
@media only screen and (max-width: 1150px) {
	.quickLook li {
	width: 33.3%;
    margin-bottom: 20px;
	}
}

		@media only screen and (max-width: 750px) {
	  .desktop {display:none;}
  .mobile {display:block;}
          
			.quickLook li {
	width: 50%;
	}
			
			.slideshow__slide--1644414617e61a87e8-0 .hero__title {
	font-size: 44px !important;
   
	margin-top: -10px;
	
}
.slideshow__slide--1644414617e61a87e8-0 .hero__subtitle {
    font-size: 20px;
	line-height: 25px;
	margin-top: 0px;
}

			
    		.subFooterMenu .site-footer__copyright-content {
			margin-left: 0px;
    		width: 100%;
    		float: left;
		    text-align: center;
			}
			.subFooterMenu ul {
    		float:none;
			width: 100%;
			text-align: center;
    		margin: 30px 0px 30px;
			}
			.subFooterMenu ul li:last-of-type {
				margin-right: 0;
			}
			
			.payment-icons {
				margin: 0px auto 20px auto;
			}
}

@media only screen and (max-width: 768px) {
.grid-overflow-wrapper .grid__item {
      width: 69vw;
    flex: 0 0 69vw;
}
    .varientWorkAround {
        font-size: calc(var(--typeBaseSize)*.92);
    }

 .grid-overflow-wrapper .grid-product__price, .grid-overflow-wrapper .grid__item {
        font-size: calc(var(--typeBaseSize)*0.92);
}
  
 
  

  
}

@-webkit-keyframes quick-add_pulse {
    0% {
        box-shadow: 0 0 0 0 hsla(0, 0%, 100%, 0.7);
    }
    70% {
        box-shadow: 0 0 0 0.8rem hsla(0, 0%, 100%, 0);
    }
    to {
        box-shadow: 0 0 0 0 hsla(0, 0%, 100%, 0);
    }
}
@keyframes quick-add_pulse {
    0% {
        box-shadow: 0 0 0 0 hsla(0, 0%, 100%, 0.7);
    }
    70% {
        box-shadow: 0 0 0 0.8rem hsla(0, 0%, 100%, 0);
    }
    to {
        box-shadow: 0 0 0 0 hsla(0, 0%, 100%, 0);
    }
}

.customQuickBuy {
    position: absolute !important;
    top: 10px;
    left: 10px;
    cursor:pointer;
    background: #fff;
    color: #000;
    border-radius: 50%;
    width: 30px;
  z-index:2;
    height: 30px;
    padding: 0px;
  text-align:center;
  -webkit-animation: quick-add_pulse 2s linear infinite;
    animation: quick-add_pulse 2s linear infinite;
}

.customQuickBuy input {
  margin: 0 auto;
  background: transparent;
  border: none;
  line-height: 14px !important;
  font-size:12px !important;
}

.customQuickBuy svg {
    position: absolute;
    left: 50%;
    top: 50%;
  width: 20px;
    height: 20px;
    margin-top: -1px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.megamenu .h5 {
  letter-spacing:0;
  font-size:0.9em;
}
.megamenu {
  border-top:1px solid #eee;
}
.megamenu .site-nav__dropdown-link:not(.site-nav__dropdown-link--top-level) {
  color:#5b5b5b;
}
shopify-accelerated-checkout {
    --shopify-accelerated-checkout-button-border-radius: 50px;
      --shopify-accelerated-checkout-button-block-size: 50px;
}
.tempGuide {
  background: #eee;
    border-radius: 5px;
    font-size: calc(var(--typeBaseSize)* .65);
    padding: 5px 7px;
  margin:5px 0px;
      display: inline-block;
 
}
 .moderate {
  background:#ebd3ae;
  
}
.cool {
  background:#afc5e2;
  
  
}
 .warm {
  background:#ecafaf;
  
}

.product__price-savings {
    color: #000;
    white-space: nowrap;
    -moz-backdrop-filter: blur(40px);
    -ms-backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    -o-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    background-color: #f5f5f5;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: uppercase;
    margin: 0;
}
.grid-product__price--savings {
    color: #000;
    margin-left: 5px;
    white-space: nowrap;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    left: 5px;
    -moz-backdrop-filter: blur(40px);
    -ms-backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    -o-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    background-color: #fefefe;
    border-radius: 4px;
    padding: 3px 8px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0;
    text-transform: uppercase;
}
.grid-product__tag--sale {
  display:none;
}
.productHelpHolder div img {
  display:inline-block;
}
